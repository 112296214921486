import React, { useState } from 'react';
import { Skeleton } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { List, ListItem, ListItemText, Divider } from '@mui/material';
import { Link } from 'react-router-dom'; // Assuming you're using React Router
import expandButton from '../../../Assets/AlertIcon/Expand Button.svg'
import Grid from '@mui/system/Unstable_Grid';
import { Box, Typography, TextField, Button, Popper } from '@mui/material';
import './styles.css'
const regionColorsText = {
  'North': '#415F8C',
  'Central A': '#825144',
  'Central B': '#6E7A2F',
  'South': '#937535',
};

const regionColorsbackground = {
  'North': '#E8F1FD',
  'Central A': '#F9E5DF',
  'Central B': '#E9F3B6',
  'South': '#FDEECE',
};

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  border: '1px solid #E8E9E9',
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  borderRadius: '8px',
  boxShadow: '0 0 1px 0 #1a1f4360',
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  height: '44px',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export default function SideNavPOC({ routes, setSelectedNav }) {
  const [searchQuery, setSearchQuery] = useState('');
  const [isDrawerOpen, setDrawerOpen] = useState(false); // State for toggling drawer

  const toggleDrawer = (open) => {
    setDrawerOpen(open);
  };
  // <IconButton 
  //       edge="start" 
  //       color="inherit" 
  //       onClick={() => toggleDrawer(true)} 
  //       sx={{ position: 'absolute', left: 16, top: 310 }} // This positions the button on the left side
  //     >   
  return (
    <>
      <Grid container>
        <Grid item xs={0.5}> </Grid>
        <Grid item xs={11.5}>
          <Box sx={{
            position: 'absolute',
            top: '40%',
            // left: '78px',
            left: 0,
            zIndex: 999,
            cursor: 'pointer'
          }}
            onClick={() => toggleDrawer(true)}
          >
            <img alt='' src={expandButton} style={{ width: '43px', height: '152px' }} />
          </Box>
          {/* For left Bar */}

        </Grid>
      </Grid>
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={() => toggleDrawer(false)}
        sx={{
          // width: 250,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            // width: 250,
            backgroundColor: '#F3FAFF',
            boxShadow: '2px 0 5px rgba(0, 0, 0, 0.2)',
          },
        }}
      >
        <div className="drawer-content"
          style={{ height: "80vh" }}
        >

          {/* Drawer Content (Dynamic Routes) */}
          {/* <div className="active_alarms_main">
            <div className="active_alarms_card_layout">
              <h2>{'Routes'}</h2>
            </div>
          </div> */}
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              textTransform: 'uppercase',
              letterSpacing: '2px',

              background: 'linear-gradient(90deg, #BD1D23, #FF5733)',
              WebkitBackgroundClip: 'text', // Ensures gradient is clipped to text
              WebkitTextFillColor: 'transparent', // Ensures background shows through text
              animation: 'gradientMove 3s ease-in-out infinite',
            }}
          >
            Routes
          </Typography>

          <Divider />

          {/* Alerts List */}
          <div className="alerts_list_main"
            style={{ height: '75vh' }}
          >
            {/* <PerfectScrollbar>
            {routes.map((route, index) => (
                <div className="single_alert_card"  onClick={() => setSelectedNav(route.id)}>
                <div className="top_row">
                  <p className="date_time">{index+1}</p>
                </div>
                <span className="alerts_info">
                  <p style={{ color: '#090C0D' }}>id:</p>
                  <p style={{ color: '#090C0D' }}>{route.id}</p>
                </span>
                <span className="alerts_info">
                  <p style={{ color: '#090C0D' }}>name:</p>
                  <p style={{ color: '#090C0D' }}>{route.routeName}</p>
                </span>
                <span className="alerts_info">
                  <p style={{ color: '#090C0D' }}>start_location:</p>
                  <p style={{ color: '#090C0D' }}>{route.start_location}</p>
                </span>
                <span className="alerts_info">
                  <p style={{ color: '#090C0D' }}>end_location:</p>
                  <p style={{ color: '#090C0D' }}>{route.end_location}</p>
                </span>
                <span className="alerts_info">
                  <p style={{ color: '#090C0D' }}>length:</p>
                  <p style={{ color: '#090C0D' }}>{route.length}</p>
                </span>
                <span className="alerts_info">
                  <p style={{ color: '#090C0D' }}>totalCost:</p>
                  <p style={{ color: '#090C0D' }}>{route.totalCost}</p>
                </span>
              </div>
            ))}
            
            </PerfectScrollbar> */}

            <PerfectScrollbar>
              <div style={{ padding: '20px', display: 'grid', gap: '20px' }}>
                {routes.map((route, index) => (
                  <div
                    key={route.id}
                    className="single_alert_card"
                    onClick={() => setSelectedNav(route.id)}
                    style={{
                      border: '1px solid #ccc',
                      borderRadius: '8px',
                      padding: '15px',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                      backgroundColor: '#fff',
                      cursor: 'pointer',
                      width: '98%',
                      transition: 'transform 0.2s',
                    }}
                    onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.02)')}
                    onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                  >
                    <div className="top_row" style={{ marginBottom: '10px', color: '#666' }}>
                      <p style={{ fontWeight: 'bold', fontSize: '14px' }}>Route #{index + 1}</p>
                    </div>
                    <div className="alerts_info" style={{ marginBottom: '8px' }}>
                      <p style={{ fontWeight: 'bold', margin: 0 }}>ID:</p>
                      <p style={{ margin: 0 }}>{route.id}</p>
                    </div>
                    <div className="alerts_info" style={{ marginBottom: '8px' }}>
                      <p style={{ fontWeight: 'bold', margin: 0 }}>Name:</p>
                      <p style={{ margin: 0 }}>{route.routeName}</p>
                    </div>
                    <div className="alerts_info" style={{ marginBottom: '8px' }}>
                      <p style={{ fontWeight: 'bold', margin: 0 }}>Start Location:</p>
                      <p
                        style={{
                          margin: 0,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          maxWidth: '150px', // Adjust width as needed
                        }}
                        title={route.start_location} // Tooltip to show full text on hover
                      >
                        {route.start_location}
                      </p>
                    </div>
                    <div className="alerts_info" style={{ marginBottom: '8px' }}>
                      <p style={{ fontWeight: 'bold', margin: 0 }}>End Location:</p>
                      <p
                        style={{
                          margin: 0,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          maxWidth: '150px', // Adjust width as needed
                        }}
                        title={route.end_location} // Tooltip to show full text on hover
                      >
                        {route.end_location}
                      </p>
                    </div>
                    <div className="alerts_info" style={{ marginBottom: '8px' }}>
                      <p style={{ fontWeight: 'bold', margin: 0 }}>Length:</p>
                      <p style={{ margin: 0 }}>{route.length}</p>
                    </div>
                    <div className="alerts_info">
                      <p style={{ fontWeight: 'bold', margin: 0 }}>Total Cost:</p>
                      <p style={{ margin: 0 }}>{route.totalCost}</p>
                    </div>
                  </div>
                ))}
              </div>
            </PerfectScrollbar>

          </div>
        </div>
      </Drawer>
    </>
  );
}
