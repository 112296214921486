import React, { useState, useEffect } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css';
import { Box, Typography, TextField, Button, Popper } from '@mui/material';
import PocEstimationModal from './poc_estimation_modal.component';
import PocEstimationModalCost from './poc_cost_modal.component';
import { useNavigate } from 'react-router-dom';
import logo from '../../Assets/Logos/logo-desk.svg';
import AppsIcon from '@mui/icons-material/Apps';
import AdminPopUp from '../../Components/TopHeader/small-right-popUp-admin.component';

const PocHeaderComponent = (props) => {
    const [openPocModal, setOpenPocModal] = useState(false);
    const [openPocModalCost, setOpenPocModalCost] = useState(false);
    const navigate = useNavigate();  // Hook to access the navigate function
    const [tabValue, setTabValue] = useState();
    const [anchor, setAnchor] = useState(null);


    const handleClick = (event) => {
        setAnchor(anchor ? null : event.currentTarget);
    };
    const { totalRouteCost, handleLockRoute, selectRoute,
        setRouteName, routeName, cancelAllRoutes,
        selectedRouteAdditional, routeSelection, setRouteSelection,
        mapMarkers, setMapMarkers, setRemoveMarkersSubRoute,
        submitAdditional, setSubmitAdditional, isToggled,
        setIsToggled } = props
    const handleClose = () => {
        setOpenPocModal(false)
    }

    // useEffect(()=>{
    //     const fetchRouteCosts = async () => {
    //        try {
    //          let routesData = await getRouteCostsForPoc(selectedRouteAdditional);
    //          setTotalRouteCost(routesData); // Save the fetched routes
    //        } catch (error) {
    //          console.error('Error fetching routes:', error);
    //        }
    //      };

    //      fetchRouteCosts();
    //      },[selectedRouteAdditional])
    const handleOpenPocModal = () => {
        setOpenPocModal(true)
    }
    const handleCloseCost = () => {
        setOpenPocModalCost(false)
    }
    const handleOpenPocModalCost = () => {
        setOpenPocModalCost(true)
    }
    const handleToggle = () => {
        setIsToggled(prev => !prev);  // Toggle the button state
    };
    const handleTabSelection = (tabValue) => {
        setTabValue(tabValue);
        sessionStorage.setItem('selectedTab', tabValue);
        // handleDrawerClose();
        navigate('/fibers')
    }

    return (
        <>
            <PocEstimationModal open={openPocModal} submitAdditional={submitAdditional} setSubmitAdditional={setSubmitAdditional}
                setRemoveMarkersSubRoute={setRemoveMarkersSubRoute} handleClose={handleClose} selectRoute={selectRoute}
                selectedRouteAdditional={selectedRouteAdditional} />
            <PocEstimationModalCost open={openPocModalCost} handleClose={handleCloseCost} handleLockRoute={handleLockRoute} setRouteName={setRouteName} routeName={routeName} />

         
             <Box
                sx={{
                    backgroundColor: '#192126',
                    border: '1px solid #333333',
                    color: '#fff',
                    height: '70px',
                    fontFamily: 'Inter, sans-serif',
                }}
            >
                <AdminPopUp anchor={anchor} setAnchor={setAnchor} handleTabSelection={handleTabSelection} />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        py: '12px',
                        mx: '16px', // Reduced for better spacing on small screens
                    }}
                >
                    <img alt='' src={logo} style={{ height: '50px', marginLeft: '17px' }} />
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '12px',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                        sx={{
                            display: 'flex',
                            gap: '12px',
                            alignItems: 'center',
                        }}
                    >
                        <Button variant="contained" sx={{
                            backgroundColor: '#3A474F',
                            fontSize: '11px',
                            '&:hover': {
                                border: '1px solid #bd1d23',
                                fontSize: '11px',
                                backgroundColor:'#2c3b3f',
                                color: 'white',
                                boxShadow: 'inset 0 0 10px #bd1d23'
                            }
                        }}
                            className='table-icon'
                            onClick={() => { navigate({ pathname: '/routeAnalysis', replace: true }) }
                            }
                        >Route Analysis</Button>
                        {routeSelection ?
                            (<Button variant="contained" sx={{
                                backgroundColor: '#3A474F',
                                fontSize: '11px',
                                '&:hover': {
                                    border: '1px solid #bd1d23',
                                    fontSize: '11px',
                                    backgroundColor:'#2c3b3f',
                                    color: 'white',
                                    boxShadow: 'inset 0 0 10px #bd1d23'
                                }
                            }}
                                className='table-icon'
                                onClick={() =>
                                    handleOpenPocModalCost()
                                    // handleLockRoute()
                                }
                            >Lock Route</Button>) :
                            (<Button variant="contained" sx={{
                                backgroundColor: '#3A474F',
                                fontSize: '11px',
                                '&:hover': {
                                    border: '1px solid #bd1d23',
                                    fontSize: '11px',
                                    backgroundColor:'#2c3b3f',
                                    color: 'white',
                                    boxShadow: 'inset 0 0 10px #bd1d23'
                                }
                            }}
                                className='table-icon'
                                onClick={() => {
                                    setRouteSelection(true)
                                }
                                    // handleLockRoute()
                                }
                            >Select Route</Button>)}

                        {mapMarkers.length == 2 && <Button variant="contained" sx={{
                            backgroundColor: '#3A474F',
                            fontSize: '11px',
                            '&:hover': {
                                border: '1px solid #bd1d23',
                                fontSize: '11px',
                                backgroundColor:'#2c3b3f',
                                color: 'white',
                                boxShadow: 'inset 0 0 10px #bd1d23'
                            }
                        }}
                            // disabled={mapMarkers.length<2 ? false : true}
                            className='table-icon'
                            onClick={handleOpenPocModal}
                        >ADD COST</Button>}
                        <Typography>
                            {totalRouteCost}
                        </Typography>
                    </Box>
                    {routeSelection &&
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '12px',
                                alignItems: 'center',
                            }}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: isToggled ? '#bd1d23' : '#3A474F',  // Change color based on toggle state
                                    fontSize: '11px',
                                    '&:hover': {
                                        backgroundColor: isToggled ? '#9c1d20' : '#2c3b3f',
                                        border: '1px solid #bd1d23',
                                        fontSize: '11px',
                                        color: 'white',
                                        boxShadow: 'inset 0 0 10px #bd1d23'
                                    }
                                }}
                                className='table-icon'
                                onClick={handleToggle}
                            >
                                {isToggled ? 'System Generated Route' : 'Create Own Route'} {/* Change text based on toggle state */}
                            </Button>
                            <Button variant="contained" sx={{
                                backgroundColor: '#3A474F',
                                fontSize: '11px',
                                '&:hover': {
                                    border: '1px solid #bd1d23',
                                    backgroundColor:'#2c3b3f',
                                    fontSize: '11px',
                                    // backgroundColor: '#bd1d23',
                                    color: 'white',
                                    boxShadow: 'inset 0 0 10px #bd1d23'
                                }
                            }}
                                className='table-icon'
                                onClick={() => {
                                    setRouteSelection(false)
                                    cancelAllRoutes()
                                }

                                    // handleLockRoute()
                                }
                            >CANCEL</Button>
                            
                        </Box>}
                        <AppsIcon sx={{ cursor: 'pointer' }} onClick={handleClick} />
                    
                    </Box>

                 </Box>
            </Box>
        </>
    );
};

export default PocHeaderComponent;